
import { defineComponent } from 'vue'
import { getBookWorkers, getWorkersStatistics, WorkerDto } from '@/api/workers'

export default defineComponent({
    name: 'Book',
    data () {
        return {
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as WorkerDto[],
            searchText: '',
            searchValue: '',
            count: {
                men: 0,
                count: 0,
                women: 0
            },
            showImageViewer: false,
            imageList: [] as string[]
        }
    },
    created () {
        this.handleCurrentChange()
        getWorkersStatistics().then(res => {
            this.count = res
        })
    },
    methods: {
        preview (row: WorkerDto) {
            this.imageList = [row.ownIdCardFront, row.idCardBack]
            this.showImageViewer = true
        },
        handleCurrentChange () {
            getBookWorkers({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                searchText: this.searchValue
            }).then(res => {
                this.total = res.total
                this.tableData = res.list
            })
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.handleCurrentChange()
        },
        onReset () {
            this.searchText = ''
            this.search()
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        closeImgViewer () {
            this.showImageViewer = false
        }
    }
})

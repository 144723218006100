import request from './request'
import { QueryPageDto, QueryMonthDto } from './dto'
// 人员信息
export interface WorkerDto{
    workerName: string
    userId: number
    userHeadUrl: string
    sex: '0' | '1'
    phone: string
    idCardNo: string
    getTotalMoney: string
    getSalaryTime: string
    createTime: string
    ownIdCardFront: string
    idCardBack: string
    serveCompanyName: string // 最后工作派遣单位
    payTotalMoney: string
    nation: string
}

// 查询员工列表
interface GetBookWorkersResDto{
    total: number
    list: WorkerDto[]
}
export function getBookWorkers (data: QueryPageDto): Promise<GetBookWorkersResDto> {
    return request.get('/rest/pay/address-book/people-list', {
        params: data
    })
}

// 查询员工分布情况
interface GetWorkersStatisticsDto{
    men: number
    count: number
    women: number
}
export function getWorkersStatistics (): Promise<GetWorkersStatisticsDto> {
    return request.get('/rest/pay/address-book/people-count')
}

export function getWorkerInfo (data: {userId: string, queryMonth: string}): Promise<WorkerDto> {
    return request.get('/rest/pay/address-book/people-info', {
        params: data
    })
}

// 查询员工工作记录
interface GetWorkerRecordDto extends QueryPageDto, QueryMonthDto{
    userId: string
}
export interface WorkInfo{
    serveCompanyName: string
    projectName: string
    payTotalMoney: string
    getTotalMoney: string
    getSalaryTime: string
    createTime: string
}
interface GetWorkerRecordResDto{
    totalCount: number,
    list: WorkInfo[]
}
export function getWorkerRecord (data: GetWorkerRecordDto): Promise<GetWorkerRecordResDto> {
    return request.get('/rest/pay/address-book/people-work-record', {
        params: data
    })
}
